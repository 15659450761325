import Hero from "./Hero"
import Form from "./Form"


const HomeScreen = ()=>{
    return(
        <div>
            <Hero/>
            <Form/>
            
        </div>
    )
};
export default HomeScreen;